import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ProtectedRoute } from "./protected.route";
import Nav from "./components/Nav";
import { Layout } from "antd";
import Logo from "./images/logo.png";
import OrderTracking from "./screens/OrderTracking";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./styles.css";
import "antd/dist/antd.css";
import Login from "./screens/Login";
import HomePage from "./screens/HomePage";
import AddOrder from "./screens/AddOrder";
import OrderDetail from "./screens/OrderDetail";
import UserManagerment from "./screens/UserManager";
import UserDetail from "./screens/UserDetail";
import AddUser from "./screens/AddUser";
import HistoryManagement from "./screens/HistoryManagement";
import ManageRevenue from "./screens/ManageRevenue";
import CatRevenue from "./screens/CatRevenue";
import HomeUser from "./screens/HomeUser";
import GetInfo from "./screens/GetInfo";
import * as API from "./code/Api";
import { HeartTwoTone } from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

const App = (props) => {
  useEffect(() => {
    let token = localStorage.getItem("token");

    API.checkToken(token)
      .then((res) => {
        if (res != "Error: Request failed with status code 401") {
          let role = localStorage.getItem("role");
          // if (role === "admin") {
          //   props.history.push("/home");
          // }
          // if (role === "customer") {
          //   props.history.push("/home-user");
          // }
          props.history.push("/update-info");
        }
      })
      .catch((err) => {});
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  function getNavbar() {
    let token = localStorage.getItem("token");
    let name = "Hello " + localStorage.getItem("name");
    if (token != null) {
      return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo">
            <img
              style={{
                width: "100%",
                objectFit: "cover",
                padding: 20,
              }}
              src={Logo}
              alt="Logo"
            />
          </div>
          <h3 style={{ color: "white", padding: "10px" }}>{name}</h3>

          <Nav {...props} />
        </Sider>
      );
    }
    return null;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {getNavbar()}

      <Layout className="site-layout" style={{ padding: 0 }}>
        {/* phần màu đen */}
        <Header
          className="site-layout-background"
          style={{ padding: 0, backgroundColor: "#99b898" }}
        />
        <Content
          style={{
            margin: "24px 16px",
            padding: "10px",
            overflow: "initial",
          }}
        >
          <Switch>
            <Route exact path="/" component={Login} />
            <ProtectedRoute path="/home" component={HomePage} />
            <ProtectedRoute path="/manager-user" component={UserManagerment} />
            <ProtectedRoute path="/add-user" component={AddUser} />
            <ProtectedRoute path="/order-tracking" component={OrderTracking} />
            <ProtectedRoute path="/order/:id" component={OrderDetail} />
            <ProtectedRoute path="/user/:id" component={UserDetail} />
            <ProtectedRoute path="/add-order" component={AddOrder} />
            <ProtectedRoute path="/history" component={HistoryManagement} />
            <ProtectedRoute path="/manage-revenue" component={ManageRevenue} />
            <ProtectedRoute path="/category-revenue" component={CatRevenue} />
            <ProtectedRoute path="/home-user" component={HomeUser} />
            <ProtectedRoute path="/update-info" component={GetInfo} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          From Oggy with <HeartTwoTone twoToneColor="#eb2f96" />| Version: 1.0.7
        </Footer>
      </Layout>
    </Layout>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);
