import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  notification,
  Select,
  Form,
  Input,
  InputNumber,
} from "antd";
import * as API from "../code/Api";
const { Option } = Select;

const AddOrder = (props) => {
  const [listCus, setListCus] = useState([]);

  // const [total, setTotal] = useState(0);

  const [quantity, setQuantity] = useState(0);

  const [price, setPrice] = useState(0);

  const [tax, setTax] = useState(0);

  const [rate, setRate] = useState(0);

  let totalPrice = 0;
  let total = 0;

  // const [totalPrice, setTotalPrice] = useState(0);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    API.getAllCustomer().then((res) => {
      setListCus(res.result);
    });
    calcPrice();
    calcTotal();
  }, []);

  const openNotification = (status) => {
    let msg, des;
    if (status == "success") {
      msg = "Thêm đơn hàng mới thành công";
      des = "Xem thêm ở trang quản lý đơn hàng ";
    } else {
      msg = "Có lỗi xảy ra";
      des = "Vui lòng thử lại";
    }
    const args = {
      message: msg,
      description: des,
      duration: 0,
    };
    notification.open(args);
  };

  function onChangeQuantiy(value) {
    setQuantity(value);
    calcPrice();
    calcTotal();
  }

  function onChangePrice(value) {
    setPrice(value);
    calcPrice();
    calcTotal();
  }

  function onChangeTax(value) {
    setTax(value);
    calcPrice();
    calcTotal();
  }

  function onChangeRate(value) {
    setRate(value);
    calcPrice();
    calcTotal();
  }

  const calcTotal = () => {
    total = (price * quantity + tax) * rate;
  };

  const calcPrice = () => {
    totalPrice = price * quantity;
  };
  calcPrice();
  calcTotal();

  const onFinish = (order) => {
    order.totalPrice = totalPrice;
    order.totalVNA = total;

    API.addOrder(order).then((res) => {
      openNotification(res.message);
      props.history.push("/order-tracking");
    });
  };

  return (
    <div>
      <h3>Thêm đơn hàng mới</h3>
      <Form {...layout} onFinish={onFinish} name="add-order">
        <Row style={{ paddingTop: "10px" }}>
          <Col span={10} style={{ borderRight: "4px solid" }}>
            <Form.Item
              name="customerId"
              label="Khách hàng"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Chọn khách hàng"
              >
                {listCus &&
                  listCus.map((cus) => (
                    <Option key={cus.id} value={cus.id}>
                      {cus.id} - {cus.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item
              name="address"
              label="Địa chỉ"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item
              name="link"
              label="Link sản phẩm"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item
              name="quantity"
              label="Số lượng"
              rules={[{ required: true }]}
            >
              <InputNumber onChange={onChangeQuantiy} min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item
              name="price"
              label="Giá sản phẩm"
              rules={[{ required: true }]}
            >
              <InputNumber
                  style={{ minWidth: "200px" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={onChangePrice}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "80px" }}>
            <h3> Tổng tiền hàng $: {totalPrice.toLocaleString()}</h3>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item
              name="rate"
              label="Rate VNĐ"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ minWidth: "300px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/(,*)/g, "")}
                onChange={onChangeRate}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="tax" label="Tax " rules={[{ required: true }]}>
              <InputNumber
                style={{ minWidth: "300px" }}
                size="large"
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={onChangeTax}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <h3> Tổng tiền VNĐ : {total.toLocaleString()} VNĐ</h3>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="note" label="Note" rules={[{ required: true }]}>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Form.Item style={style.col}>
            <Button type="primary" htmlType="submit">
              Thêm Order
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

const style = {
  col: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  inp: {
    paddingLeft: 10,
  },
};

export default AddOrder;
