import React from "react";
import { Tabs } from "antd";

import OrderList from "../components/OrderList";
const { TabPane } = Tabs;

const OrderTracking = () => {
  return (
    <Tabs defaultActiveKey="all">
      <TabPane tab="TẤT CẢ" key="all">
        <h3> Tất cả đơn hàng</h3>
        <OrderList status="ALL" />
      </TabPane>
      <TabPane tab="WAITING" key="waiting">
        <h3> Chờ xử lý</h3>
        <OrderList status="WAITING" />
      </TabPane>
      <TabPane tab="IN_PROGRESS" key="inprogress">
        <h3> Đang xử lý</h3>
        <OrderList status="IN_PROGRESS" />
      </TabPane>
      <TabPane tab="ORDER_PLACED" key="place-order">
        <h3> Đang đặt hàng</h3>
        <OrderList status="ORDER_PLACED" />
      </TabPane>
      <TabPane tab="SHIPPING_SOON" key="shipping-soon">
        <h3> Sắp giao hàng</h3>
        <OrderList status="SHIPPING_SOON" />
      </TabPane>
      <TabPane tab="IN_TRANSIT" key="in_transit">
        <h3> Đang vận chuyển</h3>
        <OrderList status="IN_TRANSIT" />
      </TabPane>
      <TabPane tab="DELIVERED" key="delivered">
        <h3> Đã giao hàng</h3>
        <OrderList status="DELIVERED" />
      </TabPane>
    </Tabs>
  );
};

export default OrderTracking;
