import React from "react";
import { Row, Col } from "antd";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import auth from "../auth";
import * as API from "../code/Api";

export const Login = (props) => {
  let token = localStorage.getItem("token");
  if (token != null) {
    auth.login(() => {
      let role = localStorage.getItem("role");
      // if (role === "admin") {
      //   props.history.push("/home");
      // }
      // if (role === "customer") {
      //   props.history.push("/home-user");
      // }
      props.history.push("/update-info");
    });
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: msg,
    });
  };

  const onFinish = (values) => {
    API.login(values).then((res) => {
      if (res.message === "success") {
        auth.login(() => {
          openNotificationWithIcon("success", "Thành công");
          localStorage.setItem("token", res.result.token);
          localStorage.setItem("role", res.result.role);
          localStorage.setItem("name", res.result.name);

          let role = localStorage.getItem("role");
          // if (role === "admin") {
          //   window.location.href = "/home";
          // }
          // if (role === "customer") {
          //   window.location.href = "/home-user";
          // }
          window.location.href = "/update-info";
        });
      } else {
        if (res === "Request failed with status code 401") {
          openNotificationWithIcon("error", "Sai tên đăng nhập hoặc tài khoản");
        } else {
          openNotificationWithIcon("error", "Tài khoản bị khoá");
        }
      }
    });
  };

  return (
    <Row>
      <Col style={{ paddingTop: "10%", background: "" }} span={12} offset={6}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="userName"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập tên đăng nhập!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Tên đăng nhập"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập mật khẩu!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Mật khẩu"
            />
          </Form.Item>
          {/* <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password
            </a>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Đăng nhập
            </Button>
            {/* Or <a href="">register now!</a> */}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
