import React, { useState, useEffect } from "react";
import { Tabs, Col, Row } from "antd";
import CatRevenueList from "../components/CatRevenueList";
import { Select, Input, Form, Button, notification, message } from "antd";
import * as API from "../code/Api";
const { Option } = Select;
const { TabPane } = Tabs;

const CatRevenue = (props) => {




  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  const [tab, setTab] = useState("all");

  const onFinish = (cat) => {
    API.addCatRevenue(cat).then((res) => {
      openNotification(res.message);
      setTab("all");
      loadData();
    });
  };

  const [listCatRevenue, setListCatRevenue] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    message.warning("Đang load dữ liệu");

    API.getCatRevenue().then((res) => {
      setListCatRevenue(res.result);
    });
  };

  const openNotification = (status) => {
    let msg, des;
    if (status == "success") {
      msg = "Thêm danh mục hàng mới thành công";
    } else {
      msg = "Có lỗi xảy ra";
      des = "Vui lòng thử lại";
    }
    const args = {
      message: msg,
      description: des,
      duration: 0,
    };
    notification.open(args);
  };
  return (
    <Tabs activeKey={tab} onChange={(t) => setTab(t)}>
      <TabPane tab="QUẢN LÝ DANH MỤC" key="all">
        <h3>Quản lý danh mục</h3>
        <CatRevenueList listcat={listCatRevenue}  callBack={loadData}/>
      </TabPane>
      <TabPane tab="THÊM MỚI" key="add">
        <Form {...layout} name="add-revenue" onFinish={onFinish}>
         <Row>
           <Col span={12}>
           <Form.Item
            label="Tên"
            name="name"
            rules={[{ required: true, message: "Vui lòng nhập tên" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Danh mục"
            name="charge"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Chọn danh mục"
            >
              <Option value={true}>Thu</Option>
              <Option value={false}>Chi</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Ghi chú" name="content">
            <Input />
          </Form.Item>

          <Form.Item style={{ marginLeft: "25%" }}>
            <Button type="primary" htmlType="submit">
              Thêm Danh mục
            </Button>
          </Form.Item>
           </Col>
         </Row>
        </Form>
      </TabPane>
    </Tabs>
  );
};

export default CatRevenue;
