import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  notification,
  Select,
  Form,
  Input,
} from "antd";
import * as API from "../code/Api";
const { Option } = Select;
const UserDetail = (props) => {
  const [id, setId] = useState();
  const [fields, setFields] = useState();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const onFinish = (user) => {

    API.updateUser(user).then((res) => {
      if (res.result != null) {
        openNotification(res.message);
        updateInfo()
      } else {
        openNotification("error");
      }
    });
 
  };

  const openNotification = (status) => {
    let msg, des;
    if (status == "success") {
      msg = "Chỉnh sửa thành công";
      des = "";
    } else {
      msg = "Có lỗi xảy ra";
      des = "Vui lòng thử lại";
    }
    const args = {
      message: msg,
      description: des,
      duration: 0,
    };
    notification.open(args);
  };

  useEffect(() => {
    
    updateInfo();
  }, []);


  const updateInfo = () =>{
    const idGet = props.match.params.id;
    setId(idGet);
    const url = "/users/get/" + idGet;
    API.getUserById(url).then((res) => {
      if (res.result.id != null) {
        const u = res.result;
        setFields([
          {
            name: ["id"],
            value: u.id,
          },
          {
            name: ["name"],
            value: u.name,
          },
          {
            name: ["address"],
            value: u.address,
          },
          {
            name: ["password"],
            value: "",
          },
          {
            name: ["facebook"],
            value: u.facebook,
          },
          {
            name: ["phone"],
            value: u.phone,
          },
          {
            name: ["dateCreate"],
            value: u.dateCreate,
          },
          {
            name: ["dateEdit"],
            value: u.dateEdit,
          },
          {
            name: ["role"],
            value: u.role,
          },
          {
            name: ["email"],
            value: u.email,
          },
          {
            name: ["status"],
            value: u.status,
          },
        ]);
      }
    });
  
  }

  return (
    <div>
      <h3>Chi tiết user #{id}</h3>
      <Form {...layout} fields={fields} onFinish={onFinish} name="add-order">
        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "3px solid" }}
          >
            <Form.Item name="id" label="Mã user" rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="name" label="Tên" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "3px solid" }}
          >
            <Form.Item name="address" label="Địa chỉ">
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="phone" label="Số điện thoại">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "3px solid" }}
          >
            <Form.Item name="facebook" label="Facebook">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "3px solid" }}
          >
            <Form.Item name="dateCreate" label="Ngày tạo">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="password" label="Mật khẩu ">
              <Input />
            </Form.Item>
            Chỉ dùng khi muốn tạo mật khẩu mới
          </Col>
        </Row>
        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "3px solid" }}
          >
            <Form.Item
              name="role"
              label="Loại tài khoản"
              rules={[{ required: true }]}
            >
              <Select placeholder="Chọn quyền">
                <Option value="staff" key="staff">
                  Nhân viên
                </Option>
                <Option value="customer" key="cuscustomer">
                  Khách hàng
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item
              name="status"
              label="Trạng thái"
              rules={[{ required: true }]}
            >
              <Select placeholder="Chọn quyền">
                <Option value="active" key="active">
                  active - Hoạt động
                </Option>
                <Option value="inactive" key="inactive">
                  inactive - Không hoạt động
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" size="large">
            Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserDetail;
