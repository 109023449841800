import React, { useState } from "react";
import {
  Table,
  Button,
  Row,
  Tag,
  Modal,
  Select,
  notification,
  Form,
  Input,
} from "antd";
import * as API from "../code/Api";
const { Option } = Select;

const CatRevenueList = (props) => {
  const [displayModal, setDisplayModal] = useState(false);

  const [ModalConent, setModalConent] = useState();

  const dataSource = props.listcat.map((c) => {
    let content;
    if (c.content != null) {
      content = c.content;
    }

    return {
      key: c.id,
      id: c.id,
      name: c.name,
      content: content,
      charge: c.charge,
    };
  });

  const getTag = (charge) => {
    if (charge) {
      return <Tag color="#87d068">Thu</Tag>;
    }
    return <Tag color="#ffa36c">Chi</Tag>;
  };

  const editCat = (c) => {
    setDisplayModal(true);
    setModalConent(
      <div>
        <Form onFinish={updateCat}>
          <Row>
            <Form.Item
              label="Mã"
              name="id"
              initialValue={c.id}
              style={{ paddingRight: 50 }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Tên"
              name="name"
              initialValue={c.name}
              rules={[{ required: true, message: "Vui lòng nhập tên" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Danh mục"
              name="charge"
              rules={[{ required: true }]}
              initialValue={c.charge}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Chọn danh mục"
              >
                <Option value={true}>Thu</Option>
                <Option value={false}>Chi</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Ghi chú" name="content" initialValue={c.content}>
              <Input />
            </Form.Item>

            <Form.Item style={{ marginLeft: "25%" }}>
              <Button
                style={{ marginLeft: 20 }}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  setDisplayModal(false);
                }}
              >
                Cập nhật
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
    // modal.info({
    //   title: "Cập nhật",
    //   visible: false,
    //   okText: "Thoát",

    //   content: { getModalConent },
    // });
  };

  const deleteCat = (id) => {
    let result = window.confirm("Bạn có muốn xoá");
    if (result) {
      const urlDelete = "/revenue/remove-category/" + id;
      API.deleteCatRevenue(urlDelete)
        .then((res) => {
          if (res.result != null) {
            openNotification("success", "Cập nhật thành công");
            props.callBack();
          } else {
            openNotification("err", "Bị phụ thuộc không thể xoá");
          }
        })
        .catch((er) => {
          openNotification("Có lỗi xảy ra vui lòng thử lại");
        });
    }
  };

  const updateCat = (value) => {
    setDisplayModal(false);
    API.editCatRevenue(value)
      .then((res) => {
        openNotification("success", "Cập nhật thành công");
        props.callBack();
      })
      .catch((er) => {
        openNotification("Có lỗi xảy ra vui lòng thử lại");
      });
  };

  const openNotification = (status, err) => {
    let msg, des;
    if (status === "success") {
      msg = "Cập nhật thành công";
      des = "";
    } else {
      msg = "Có lỗi xảy ra";
      des = err;
    }
    const args = {
      message: msg,
      description: des,
      duration: 0,
    };
    notification.open(args);
  };

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Mã danh mục</div>,
      key: "id",
      render(c) {
        return <div style={{ textAlign: "center" }}>{c.id}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Tên</div>,
      key: "name",
      render(c) {
        return <div style={{ textAlign: "left" }}>{c.name}</div>;
      },
    },

    {
      title: <div style={{ textAlign: "center" }}>Thu chi</div>,
      key: "charge",
      render(c) {
        return <div style={{ textAlign: "center" }}>{getTag(c.charge)}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Nội dung chi tiết</div>,
      key: "content",
      render(c) {
        return (
          <div style={{ textAlign: "center", maxWidth: 200 }}>{c.content}</div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Hành động</div>,
      key: "delete",
      render(c) {
        return (
          <div>
            <Button type="primary" onClick={() => editCat(c)}>
              Chỉnh sửa
            </Button>

            <Button style={{ marginLeft: 20 }} onClick={() => deleteCat(c.id)}>
              Xoá
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Row>
      <Modal
        onCancel={() => setDisplayModal(false)}
        destroyOnClose={true}
        visible={displayModal}
        title="Chỉnh sửa danh mục"
        footer={null}
      >
        {ModalConent}
      </Modal>

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ["50", "70", "100"],
        }}
      />
    </Row>
  );
};

export default CatRevenueList;
