import React from "react";
import { Button, notification, Select, Form, Input } from "antd";
import * as API from "../code/Api";
const { Option } = Select;
const AddUser = (props) => {


  const onFinish = (user) => {

    API.registerAdmin(user).then((res) => {
      openNotification(res.result);
      if (res.result.role != null) {
        props.history.push("/manager-user");
      }
    });
  };

  const openNotification = (result) => {
    let msg, des;
    if (result.role != null) {
      msg = "Thêm tài khoản mới thành công";
      des = "Xem thêm ở trang quản tài khoản " + result.role;
    } else {
      msg = "Có lỗi xảy ra";
      if (result.message === "duplicate") {
        des = "Email bị trùng vui lòng thử email khác";
      }
    }
    const args = {
      message: msg,
      description: des,
      duration: 0,
    };
    notification.open(args);
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 6 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  return (
    <div>
      <h3>Thêm User</h3>
      <Form {...layout} onFinish={onFinish} name="add-user">
        <Form.Item name="name" label="Tên" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role"
          label="Loại tài khoản"
          rules={[{ required: true }]}
        >
          <Select  placeholder="Chọn quyền">
            <Option value="staff" key="staff">
              Nhân viên
            </Option>
            <Option value="customer" key="customer">
              Khách hàng
            </Option>
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "50px" }}
          >
            Thêm tài khoản
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddUser;
