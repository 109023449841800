import axios from "axios";
axios.defaults.baseURL = "https://api.dpsmartsolutions.com/";
//axios.defaults.baseURL = "http://localhost:8080/";

const getToken = () => {
  let token =   localStorage.getItem('token') ;

  return token;
}

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "Bearer " + getToken()
    },
};


export const login = (login) => {
  return axios
    .post("/token/generate-token", login)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const registerAdmin = (account) => {
  return axios
    .post("/users/register-admin", account, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const register = (account) => {
  return axios
    .post("/users/register", account, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const getAllOrder = () => {
  return axios
    .get("/order/all", config)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const getAllCustomer = () => {
  return axios
    .get("/users/get-customer", config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const getAllStaff = () => {
  return axios
    .get("/users/get-staff", config)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const addOrder = (order) => {
  return axios
    .post("/order/new", order, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const updateOrder = (order) => {
  return axios
    .post("/order/update", order, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const updateUser = (user) => {
  return axios
    .post("/users/update", user, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};



export const getOrder = (url) => {
  return axios
    .get(url, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const deleteOrder = (url) => {
  return axios
    .get(url, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const getUserById = (url) => {
  return axios
    .get(url, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};




export const getAllUser = () => {
  return axios
    .get("/users/listAll", config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const checkToken = () => {
  return axios
    .get("/users/check-token", config)
    .then((response) => response.data)
    .catch((error) => error);
};


export const getOrderByStatus = (order) => {
  return axios
    .post("/order/get-by-status", order, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const searchByStaffCus = (search) => {
  return axios
    .post("/order/searchByStaffCus", search, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const getHistory = () => {
  return axios
    .get("/system/history", config)
    .then((response) => response.data)
    .catch((error) => error);
};


export const reportByStatus = (search) => {
  return axios
    .post("/order/report", search, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const reportFull = () => {
  return axios
    .get("/order/report-full", config)
    .then((response) => response.data)
    .catch((error) => error);
};

export const reportFullId = (search) => {
  return axios
    .post("/order/report-full-id", search, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const reportFullIdList = (status) => {
  return axios
    .post("/order/report-full-list", status, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};



export const getLastUpdate = () => {
  return axios
    .get("/order/last-update", config)
    .then((response) => response.data)
    .catch((error) => error);
};



export const getAllRevenue = () => {
  return axios
    .get("/revenue/get-all", config)
    .then((response) => response.data)
    .catch((error) => error);
};


export const getCatRevenue = () => {
  return axios
    .get("/revenue/get-category", config)
    .then((response) => response.data)
    .catch((error) => error);
};



export const addCatRevenue = (revenue) => {
  return axios
    .post("/revenue/add-category", revenue, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const editCatRevenue = (revenue) => {
  return axios
    .post("/revenue/update-category", revenue, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};



export const deleteCatRevenue = (url) => {
  return axios
    .get(url, config)
    .then((response) => response.data)
    .catch((error) => error);
};


export const addRevenue = (revenue) => {
  return axios
    .post("/revenue/add", revenue, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const editRevenue = (revenue) => {
  return axios
    .post("/revenue/edit", revenue, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};



export const deleteRevenue = (url) => {
  return axios
    .get(url, config)
    .then((response) => response.data)
    .catch((error) => error);
};


export const getOrderByCus = () => {
  return axios
    .get("/order/get-by-user", config)
    .then((response) => response.data)
    .catch((error) => error);
};


export const getOrderByCustomerAndStatus = (order) => {
  return axios
    .post("/order/get-by-user-status", order, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const getInfo = (list) => {
  return axios
    .post("/system/get-info-product", list, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};

export const getStatus = (list) => {
  return axios
    .post("/system/update-list-status", list, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};


export const getInfoProductUser = () => {
  return axios
    .get("/system/get-info-product-user", config)
    .then((response) => response.data)
    .catch((error) => error);
};

export const getListProductUser = () => {
  return axios
    .get("/system/get-list-product-user", config)
    .then((response) => response.data)
    .catch((error) => error);
};


export const editListProductInfoUser = (list) => {
  return axios
    .post("/system/edit-list-product", list, config)
    .then((response) => response.data)
    .catch((error) => error.message);
};
