import React from "react";
import { Table, Button, Row, Tag } from "antd";

const ListInfo = (props) => {
  const dataSource = props.info.map((o) => {
    if (o !== undefined) {
      return {
        key: o.link,
        link: o.link,
        listPrice: o.listPrice,
        price: o.price,
        yourSave: o.yourSave,
        name: o.name,
        status: o.status,
      };
    }
  });

  const columns = [
    {
      title: "Link",
      key: "link",
      render(o) {
        return (
          <div style={{ textAlign: "center"}}>
            <Button type="primary">
              <a href={o.link}>Link</a>
            </Button>
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Tên</div>,
      key: "name",
      render(o) {
        return (
          <div style={{ textAlign: "center", maxWidth: "400px" , paddingLeft: 10, paddingRight: 10 }}>
            <h3>{o.name}</h3>
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Price</div>,
      key: "price",
      render(o) {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color="#f69e7b">
              {" "}
              <h3 style={{ padding: 5, color: "white", fontSize:18 }}>{o.price}</h3>
            </Tag>
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Your Save</div>,
      key: "yourSave",
      render(o) {
        return (
          <div style={{ textAlign: "center",  fontSize:18  }}>
            <Tag color="#07689f">
              {" "}
              <h3 style={{ padding: 5, color: "white" }}>{o.yourSave}</h3>
            </Tag>
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Status</div>,
      key: "status",
      render(o) {
        return (
          <div style={{ textAlign: "center" }}>
            <Tag color="#87d068">
              {" "}
              <h3 style={{ padding: 5, color: "white" }}>{o.status}</h3>
            </Tag>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <Row>
          <Table
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            dataSource={dataSource}
            columns={columns}
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: ["50", "70", "100"],
            }}
          />
        </Row>
      </div>
    </div>
  );
};

export default ListInfo;
