import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, message, Select, Form, Tabs } from "antd";
import * as API from "../code/Api";
import OrderListReport from "../components/OrderListReport";

const { Option } = Select;
const { TabPane } = Tabs;

const HomePage = () => {
  const [listReport, setListReport] = useState([]);
  const [listReportDetail, setListReportDetail] = useState([]);
  const [listReportListFull, setListReportListFull] = useState([]);
  const [listOrder, setListOrder] = useState([]);
  const [listCus, setListCus] = useState([]);
  const [tab, setTab] = useState("all");
  const [seletedValue, setSeletedValue] = useState("customer");
  const [fields, setFields] = useState([
    {
      name: ["userId"],
      value: "Chọn khách hàng hoặc nhân viên",
    },
  ]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    message.warning("Đang load dữ liệu");

    API.getAllUser().then((res) => {
      setListCus(res.result);
    });
  };

  const updateInfo = () => {
    message.warning("Đang load dữ liệu");
    API.reportFull().then((res) => {
      setListReport(res.result);
    });
  };

  const onFinishSearch = (value) => {
    if (value.userId != undefined && value.userId > 0) {
      setFields([
        {
          name: ["userId"],
          value: value.userId,
        },
      ]);
      message.warning("Đang tìm kiếm");
      value.statusOrder = "ALL";
      API.searchByStaffCus(value).then((res) => {
        setListOrder(res.result);
      });
      let search = { id: value.userId };
      API.reportFullId(search).then((res) => {
        if (res.result[0].numberOrder > 0) {
          setListReportDetail(res.result);
          message.success("Tìm kiếm thành công");
        } else {
          message.warn("Chưa có đơn hàng");
          setListReportDetail([]);
        }
      });
    }
  };
  const getRole = (roleId) => {
    if (roleId == "admin") {
      return "Admin";
    }
    if (roleId == "staff") {
      return "Nhân viên";
    }

    if (roleId == "customer") {
      return "Khách hàng";
    }

    return "";
  };

  const columns = [
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "200px" }}>Mã ĐH</div>
      ),
      key: "id",
      render(o) {
        return <div style={{ textAlign: "left" }}>{o.name}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Số Order</div>,
      key: "numberOrder",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.numberOrder}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Số tiền</div>,
      key: "numberOrder",
      render(o) {
        return (
          <div
            style={{
              textAlign: "right",
              paddingRight: "20px",
              fontWeight: "bold",
            }}
          >
            {getPrice(o.totalPrice)}
          </div>
        );
      },
    },
  ];

  const getPrice = (price) => {
    if (price != null) {
      return price.toLocaleString() + " VNĐ";
    }
    return 0 + " VNĐ";
  };

  useEffect(() => {
    updateInfo();
    updateReport(seletedValue);
  }, []);

  const updateReport = (value) => {
    let status = { status: value };

    API.reportFullIdList(status).then((res) => {
      setListReportListFull(res.result);
    });
  };

  const onChangeSelect = (value) => {
    setSeletedValue(value);
    updateReport(value);
  };

  const columnsList = [
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "200px" }}>
          Tên khách hàng
        </div>
      ),
      key: "id",
      render(o) {
        return <div style={{ textAlign: "left" }}>{o.name}</div>;
      },
    },
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "200px" }}>
          Tất cả đơn hàng
        </div>
      ),
      key: "all",
      render(o) {
        return (
          <div style={{ textAlign: "left" }}>
            {o.report[0].numberOrder} Đơn |{" "}
            <span
              style={{
                textAlign: "right",
                paddingRight: "20px",
                fontWeight: "bold",
              }}
            >
              {getPrice(o.report[0].totalPrice)}
            </span>{" "}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "200px" }}>
          Đang chờ xử lý
        </div>
      ),
      key: "waiting",
      render(o) {
        return (
          <div style={{ textAlign: "left" }}>
            {o.report[1].numberOrder} Đơn |{" "}
            <span
              style={{
                textAlign: "right",
                paddingRight: "20px",
                fontWeight: "bold",
              }}
            >
              {getPrice(o.report[1].totalPrice)}
            </span>{" "}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "200px" }}>Đang xử lý</div>
      ),
      key: "waiting",
      render(o) {
        return (
          <div style={{ textAlign: "left" }}>
            {o.report[2].numberOrder} Đơn |{" "}
            <span
              style={{
                textAlign: "right",
                paddingRight: "20px",
                fontWeight: "bold",
              }}
            >
              {getPrice(o.report[2].totalPrice)}
            </span>{" "}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "200px" }}>
          Đã hoàn thành
        </div>
      ),
      key: "waiting",
      render(o) {
        return (
          <div style={{ textAlign: "left" }}>
            {o.report[3].numberOrder} Đơn |{" "}
            <span
              style={{
                textAlign: "right",
                paddingRight: "20px",
                fontWeight: "bold",
              }}
            >
              {getPrice(o.report[3].totalPrice)}
            </span>{" "}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: "center", maxWidth: "200px" }}>Thanh toán</div>
      ),
      key: "waiting",
      render(o) {
        return (
          <div style={{ textAlign: "left" }}>
            {o.report[4].numberOrder} Đơn |{" "}
            <span
              style={{
                textAlign: "right",
                paddingRight: "20px",
                fontWeight: "bold",
              }}
            >
              {getPrice(o.report[4].totalPrice)}
            </span>{" "}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center", maxWidth: "200px" }}>Huỷ</div>,
      key: "waiting",
      render(o) {
        return (
          <div style={{ textAlign: "left" }}>
            {o.report[5].numberOrder} Đơn |{" "}
            <span
              style={{
                textAlign: "right",
                paddingRight: "20px",
                fontWeight: "bold",
              }}
            >
              {getPrice(o.report[5].totalPrice)}
            </span>{" "}
          </div>
        );
      },
    },
    {
      title: "",
      key: "action",
      render(o) {
        return (
          <Button
            type="primary"
            onClick={() => {
              onFinishSearch({ userId: o.id });
              setTab("custom");
            }}
          >
            Chi tiết
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Tabs
        activeKey={tab}
        onChange={(e) => {
          setTab(e);
        }}
      >
        <TabPane tab="TẤT CẢ" key="all">
          <Row>
            <Table
              pagination={false}
              rowKey={(record) => record.name}
              dataSource={listReport}
              columns={columns}
            />
          </Row>
          <Row
            style={{
              marginTop: "30px",
              marginBottom: "10px",
            }}
          >
            <Col span={5}>
              <h3 style={{ fontSize: "20px" }}>Danh sách đơn hàng </h3>
            </Col>
            <Col span={5}>
              <Select
                defaultValue="customer"
                style={{ minWidth: "100px" }}
                onChange={onChangeSelect}
              >
                <Option key="customer" value="customer">
                  Khách
                </Option>
                <Option key="staff" value="staff">
                  Nhân viên
                </Option>
              </Select>
            </Col>
          </Row>
          {/* report 2 nè */}
          <Row>
            <Table
              pagination={true}
              rowKey={(record) => record.name}
              dataSource={listReportListFull}
              columns={columnsList}
            />
          </Row>
        </TabPane>
        <TabPane tab="CHI TIẾT" key="custom">
          <Form onFinish={onFinishSearch} name="search-order" fields={fields}>
            <Row style={{ padding: "10px 0" }}>
              <Col span={10}>
                <Form.Item name="userId">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Chọn khách hàng hoặc nhân viên"
                    optionFilterProp="children"
                  >
                    <Option
                      disabled
                      key="Chọn khách hàng hoặc nhân viên"
                      value="Chọn khách hàng hoặc nhân viên"
                    >
                      Chọn khách hàng hoặc nhân viên
                    </Option>
                    {listCus &&
                      listCus.map((cus) => (
                        <Option key={cus.id} value={cus.id}>
                          {cus.id} - {cus.name} - {getRole(cus.role)}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={2} style={{ marginLeft: "10px" }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Tìm kiếm
                  </Button>
                </Form.Item>
              </Col>

              <Col span={4} style={{ marginLeft: "10px" }}></Col>
            </Row>
          </Form>

          <Row>
            <Table
              pagination={false}
              rowKey={(record) => record.name}
              dataSource={listReportDetail}
              columns={columns}
            />
          </Row>

          <OrderListReport listOrder={listOrder} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default HomePage;
