import React, { useState, useEffect } from "react";
import { Tabs, Select, Button, Form, Input, InputNumber, message } from "antd";
import RevenueList from "../components/RevenueList";
import * as API from "../code/Api";

const { Option, OptGroup } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ManageRevenue = () => {
  const [listCus, setListCus] = useState([]);
  const [listCharge, setListCharge] = useState([]);
  const [listReCharge, setListReCharge] = useState([]);
  const [form] = Form.useForm();
  const [tab, setTab] = useState("all");

  const [listRevenue, setListRevenue] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    API.getAllUser().then((res) => {
      setListCus(res.result);
    });
    let listCharge = [],
      listReCharge = [];
    API.getCatRevenue().then((res) => {
      res.result.map((c) => {
        if (c.charge) {
          listCharge.push(c);
        } else {
          listReCharge.push(c);
        }
      });
      setListCharge(listCharge);
      setListReCharge(listReCharge);
    });

    message.warning("Đang load dữ liệu");
    API.getAllRevenue().then((res) => {
      setListRevenue(res.result);
    });
  };

  const getRole = (role) => {
    if (role === "admin") {
      return "Quản lý";
    }

    if (role === "customer") {
      return "Khách hàng";
    }
    if (role === "staff") {
      return "Nhân viên";
    }
  };

  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 12 },
  };

  const addCharge = (revenue) => {

    API.addRevenue(revenue).then((re) => {
      loadData();
      form.resetFields();
      setTab("all");
    });
  };

  return (
    <Tabs activeKey={tab} onChange={(t) => setTab(t)}>
      <TabPane tab="QUẢN LÝ THU CHI" key="all">
        <h3>Quản lý thu chi</h3>
        <RevenueList listRevenue={listRevenue} listCus={listCus} 
        listCharge={listCharge}
        listReCharge={listReCharge}
        callBack={loadData}
        />
      </TabPane>
      <TabPane tab="THÊM MỚI" key="add">
        <Form {...layout} onFinish={addCharge} form={form}>
          <Form.Item
            name="content"
            label="Nội dung"
            rules={[{ required: true }]}
          >
            <TextArea rows={2} />
          </Form.Item>

          <Form.Item name="price" label="Số tiền" rules={[{ required: true }]}>
            {/* <InputNumber style={{ minWidth: 200 }} /> */}
            <InputNumber
              style={{ minWidth: "300px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/(,*)/g, "")}
            />
          </Form.Item>

          <Form.Item name="customer" label="Khách hàng">
            <Select
              showSearch
              style={{ width: 300 }}
              placeholder="Chọn khách hàng"
            >
              <Option key={undefined}>Không chọn</Option>
              {listCus &&
                listCus.map((cus) => (
                  <Option key={cus.id} value={cus.id}>
                    {cus.id} - {cus.name} - {getRole(cus.role)}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="categoryId"
            label="Danh mục"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Chọn danh mục"
            >
              <OptGroup label="Chi">
                {listCharge &&
                  listCharge.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.id} - {c.name}
                    </Option>
                  ))}
              </OptGroup>
              <OptGroup label="Thu">
                {listReCharge &&
                  listReCharge.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.id} - {c.name}
                    </Option>
                  ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item
            name="note"
            label="Ghi chú"
       
          >
            <TextArea rows={2} />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ marginLeft: "10%" }}
              type="primary"
              htmlType="submit"
            >
              Thêm
            </Button>
          </Form.Item>
        </Form>
      </TabPane>
    </Tabs>
  );
};

export default ManageRevenue;
