import React, { useState, useEffect } from "react";
import { Table, Row, Col, Checkbox,  } from "antd";
import { Link } from "react-router-dom";
import * as API from "../code/Api";


const OrderListReport = (props) => {


  const getTax = (tax) => {
    if (tax == null) {
      return 0;
    } else {
      return tax;
    }
  };

  const getLink = (link) => {
    if (link == null) {
      return null;
    }

    let a = link.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\./im) + "";

    a = a.replace("www", "");
    a = a.replace(".", "");
    a = a.replace(".", "");
    return a;
  };

  useEffect(() => {
  
  }, []);




  const dataSource = ( props.listOrder).map((o) => {

    let customerName,
      customerId,
      link,
      quantity,
      price,
      checker,
      trackingLink,
      totalVNA,
      status,
      note,
      tax,
      address,
      orderNumber,
      trackingTBA,
      rate,
      dateCreate,
      dateEdit,
      editBy,
      statusMode;

    if (o.customer != null) {
      if (o.customer.name != null) {
        customerName = o.customer.name;
      }
      if (o.order.customerId != null) {
        customerId = o.order.customerId;
      }
    }

    if (o.order.link != null) {
      link = o.order.link;
    }

    if (o.order.link != null) {
      link = o.order.link;
    }

    if (o.order.quantity != null) {
      quantity = o.order.quantity;
    }

    if (o.order.price != null) {
      price = o.order.price;
    } else {
      price = 0;
    }

    if (o.staff != null) {
      if (o.staff.name != null) {
        checker = o.staff.name;
      }
    }

    if (o.order.trackingLink != null) {
      trackingLink = o.order.trackingLink;
    }
    if (o.order.totalVNA != null) {
      totalVNA = o.order.totalVNA;
    } else {
      totalVNA = 0;
    }

    if (o.order.status != null) {
      status = o.order.status;
    }
    if (o.order.note != null) {
      note = o.order.note;
    }
    if (o.order.tax != null) {
      tax = o.order.tax;
    }
    if (o.order.address != null) {
      address = o.order.address;
    }
    if (o.order.orderNumber != null) {
      orderNumber = o.order.orderNumber;
    }
    if (o.order.trackingTBA != null) {
      trackingTBA = o.order.trackingTBA;
    }
    if (o.order.rate != null) {
      rate = o.order.rate;
    }
    if (o.order.dateCreate != null) {
      dateCreate = o.order.dateCreate;
    }
    if (dateEdit != null) {
      price = o.order.dateEdit;
    }
    if (o.order.editBy != null) {
      editBy = o.order.editBy;
    }

    return {
      key: o.order.id,
      id: o.order.id,
      customerName: customerName,
      customerId: customerId,
      link: link,
      quantity: quantity,
      price: price,
      checker: checker,
      trackingLink: trackingLink,
      totalVNA: totalVNA,
      status: status,
      note: note,
      tax: tax,
      address: address,
      orderNumber: orderNumber,
      trackingTBA: trackingTBA,
      rate: rate,
      dateCreate: dateCreate,
      dateEdit: dateEdit,
      editBy: editBy,
      statusMode: statusMode,
    };
  });

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Mã ĐH</div>,
      key: "id",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.id}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Link</div>,
      key: "link",
      render(o) {
        return (
          <div style={{ textAlign: "center" }}>
            <a target="_blank" href={o.link}>
              {getLink(o.link)}
            </a>
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Ghi chú</div>,
      key: "note",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.note}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Số lượng</div>,
      key: "quantity",
      render(o) {
        return (
          <div style={{ textAlign: "center", width: "50px !important" }}>
            {o.quantity}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Giá</div>,
      key: "quantity",
      render(o) {
        return (
          <div style={{ width: "50px !important", textAlign: "center" }}>
            $ {o.price.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Tổng tiền</div>,
      key: "quantity",
      render(o) {
        return (
          <div style={{ textAlign: "center" }}>
            {o.totalVNA.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Phụ phí</div>,
      key: "tax",
      render(o) {
        return <div style={{ textAlign: "center" }}>{getTax(o.tax)}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Tên khách hàng</div>,
      key: "customerName",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.customerName}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Tên NV</div>,
      key: "checker",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.checker}</div>;
      },
    },

    {
      title: <div style={{ textAlign: "center" }}>Trạng thái</div>,
      key: "status",
      render(o) {
        let bgColor = "#ff000000";
        let color = "#000";
        if (o.status == "WAITING") {
          bgColor = "#e79c2a";
          color = "#fff";
        } else if (o.status == "IN_PROGRESS") {
          bgColor = "#3282b8";
          color = "#fff";
        } else if (o.status == "DELIVERED") {
          bgColor = "#0f4c75";
          color = "#fff";
        } else if (o.status == "ORDER_PLACED") {
          bgColor = "#96bb7c";
          color = "#fff";
        } else if (o.status == "SHIPPING_SOON") {
          bgColor = "#436f8a";
          color = "#fff";
        } else if (o.status == "IN_TRANSIT") {
          bgColor = "#ffa931";
          color = "#fff";
        } else if (o.status == "ORDER_CANCELLED") {
          bgColor = "#ff4301";
          color = "#fff";
        } else {
          bgColor = "#24a19c";
          color = "#fff";
        }

        return (
          <div
            style={{
              textAlign: "center",
              background: bgColor,
              color: color,
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            {o.status}
          </div>
        );
      },
    },
   
  ];

  

  return (
    <div>
      <div>
        <div style={{ padding: "10px" }}></div>

       
       <Row>
       <Table
          style={{
            width: "auto",
            textOverflow: "ellipsis",
            // overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          dataSource={dataSource}
          columns={columns}
          expandable={{
            expandedRowRender: (o) => (
              <div>
                <Row style={{}}>
                  <Col span={24}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Địa chỉ:</span>{" "}
                      {o.address}
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={10} style={{ borderRight: "4px solid" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Nhân viên:
                      </span>{" "}
                      {o.checker}
                    </p>
                  </Col>
                  <Col span={12} style={{ marginLeft: "10px" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        OrderNumber:
                      </span>{" "}
                      {o.orderNumber}
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={10} style={{ borderRight: "4px solid" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Ngày tạo:</span>{" "}
                      {o.dateCreate}
                    </p>
                  </Col>
                  <Col span={12} style={{ marginLeft: "10px" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Tracking Link:
                      </span>{" "}
                      <a href={o.trackingLink}>{o.trackingLink}</a>
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={10} style={{ borderRight: "4px solid" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Rate:</span> {o.rate}
                    </p>
                  </Col>
                  <Col span={12} style={{ marginLeft: "10px" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Tracking TBA:
                      </span>{" "}
                      {o.trackingTBA}
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={10} style={{ borderRight: "4px solid" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Chỉnh sửa bởi:
                      </span>{" "}
                      {o.dateEdit}
                    </p>
                  </Col>
                  <Col span={12} style={{ marginLeft: "10px" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Vào lúc:</span>{" "}
                      {o.editBy}
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={10} style={{ borderRight: "4px solid" }}>
                    <div>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Tự động cập nhật trạng thái:
                      </span>{" "}
                      <Checkbox disabled checked={o.statusMode} />
                    </div>
                  </Col>
                  <Col span={12} style={{ marginLeft: "10px" }}>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>Trạng thái:</span>{" "}
                    {o.status}
                  </Col>
                </Row>
              </div>
            ),
          }}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "70"],
          }}
        />
       </Row>
      </div>
    </div>
  );
};


export default OrderListReport;
