import React from "react";
import { Table } from "antd";

const HistoryList = (props) => {

  const dataSource = props.history.map((h) => {
    let content = h.content.split(', ').map(i => {
      return <p>{i}</p>
  });

    return {
      key: h.id,
      id: h.id,
      content: content,
      dateCreate: h.dateCreate,
      history: h.history,
    };
  });

  const columns = [
    {
      title: "Mã Lịch sử",
      key: "id",
      render(h) {
        return <div style={{ textAlign: "center !important" }}>{h.id}</div>;
      },
    },
    {
      title: "Nội dung",
      key: "history",
      render(h) {
        return (
          <div style={{ textAlign: "center !important " }}>{h.history}</div>
        );
      },
    },

    {
      title: "Ngày tạo",
      key: "dateCreate",
      render(h) {
        return (
          <div style={{ textAlign: "center !important" }}>{h.dateCreate}</div>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <Table
          style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
          dataSource={dataSource}
          columns={columns}
          expandable={{
            expandedRowRender: (o) => <p>{o.content}</p>,
          }}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: ["50", "100", "200"],
          }}
        />
      </div>
    </div>
  );
};

export default HistoryList;
