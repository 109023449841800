import React, { useState, useEffect } from "react";
import { message } from "antd";
import * as API from "../code/Api";
import HistoryList from "../components/HistoryList";
const HistoryManagement = (props) => {
  const [listHistory, setListHistory] = useState([]);


  useEffect(() => {
    message.warning("Đang load dữ liệu");
    API.getHistory().then((res) => {
      setListHistory(res.result);
    });
  }, []);

  return <div>
   <HistoryList history={listHistory}/>
  </div>;
};

export default HistoryManagement;
