import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Checkbox, message, Select, Form } from "antd";
import { Link } from "react-router-dom";
import * as API from "../code/Api";

const { Option } = Select;

const OrderListCustomer = (props) => {
  const getTax = (tax) => {
    if (tax == null) {
      return 0;
    } else {
      return tax;
    }
  };

  const getLink = (link) => {
    let a = link.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\./im) + "";

    a = a.replace("www", "");
    a = a.replace(".", "");
    a = a.replace(".", "");
    if (a == "null") {
      return "Link lỗi";
    }
    return (
      <a target="_blank" href={link}>
        {a}
      </a>
    );
  };

  const [listOrder, setListOrder] = useState([]);
  const [listCus, setListCus] = useState([]);
  const [timeUpdate, setTimeUpdate] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    let statusOrder = props.status;
    message.warning("Đang load dữ liệu");
    if (statusOrder == "ALL") {
      API.getOrderByCus().then((res) => {
        setListOrder(res.result);
      });
    } else {
      let order = { status: statusOrder };
      API.getOrderByCustomerAndStatus(order).then((res) => {
        setListOrder(res.result);
      });
    }
  };

  const onFinishSearch = (value) => {
    value.statusOrder = props.status;
    if (value.userId != undefined) {
      message.warning("Đang tìm kiếm");

      API.searchByStaffCus(value).then((res) => {
        setListOrder(res.result);
        message.success("Tìm kiếm thành công");
      });
    }
  };

  const dataSource = listOrder.map((o) => {
    let customerId,
      link,
      quantity,
      price,
      trackingLink,
      totalVNA,
      status,
      tax,
      address,
      orderNumber,
      trackingTBA,
      rate,
      dateCreate;

    if (o.link != null) {
      link = o.link;
    }

    if (o.quantity != null) {
      quantity = o.quantity;
    }

    if (o.price != null) {
      price = o.price;
    } else {
      price = 0;
    }

    if (o.trackingLink != null) {
      trackingLink = o.trackingLink;
    }
    if (o.totalVNA != null) {
      totalVNA = o.totalVNA;
    } else {
      totalVNA = 0;
    }

    if (o.status != null) {
      status = o.status;
    }

    if (o.tax != null) {
      tax = o.tax;
    }
    if (o.address != null) {
      address = o.address;
    }
    if (o.orderNumber != null) {
      orderNumber = o.orderNumber;
    }
    if (o.trackingTBA != null) {
      trackingTBA = o.trackingTBA;
    }
    if (o.rate != null) {
      rate = o.rate;
    }
    if (o.dateCreate != null) {
      dateCreate = o.dateCreate;
    }

    return {
      key: o.id,
      id: o.id,
      customerId: customerId,
      link: link,
      quantity: quantity,
      price: price,
      trackingLink: trackingLink,
      totalVNA: totalVNA,
      status: status,
      tax: tax,
      address: address,
      orderNumber: orderNumber,
      trackingTBA: trackingTBA,
      rate: rate,
      dateCreate: dateCreate,
    };
  });

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Mã ĐH</div>,
      key: "id",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.id}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Link</div>,
      key: "link",
      render(o) {
        return <div style={{ textAlign: "center" }}>{getLink(o.link)}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Ghi chú</div>,
      key: "note",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.note}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Số lượng</div>,
      key: "quantity",
      render(o) {
        return (
          <div style={{ textAlign: "center", width: "50px !important" }}>
            {o.quantity}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Giá</div>,
      key: "quantity",
      render(o) {
        return (
          <div style={{ width: "50px !important", textAlign: "center" }}>
            $ {o.price.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Tổng tiền</div>,
      key: "quantity",
      render(o) {
        return (
          <div style={{ textAlign: "center" }}>
            {o.totalVNA.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Phụ phí</div>,
      key: "tax",
      render(o) {
        return <div style={{ textAlign: "center" }}>{getTax(o.tax)}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Trạng thái</div>,
      key: "status",
      render(o) {
        let bgColor = "#ff000000";
        let color = "#000";
        if (o.status == "WAITING") {
          bgColor = "#e79c2a";
          color = "#fff";
        } else if (o.status == "IN_PROGRESS") {
          bgColor = "#3282b8";
          color = "#fff";
        } else if (o.status == "DELIVERED") {
          bgColor = "#0f4c75";
          color = "#fff";
        } else if (o.status == "ORDER_PLACED") {
          bgColor = "#96bb7c";
          color = "#fff";
        } else if (o.status == "SHIPPING_SOON") {
          bgColor = "#436f8a";
          color = "#fff";
        } else if (o.status == "IN_TRANSIT") {
          bgColor = "#ffa931";
          color = "#fff";
        } else if (o.status == "ORDER_CANCELLED") {
          bgColor = "#ff4301";
          color = "#fff";
        } else {
          bgColor = "#24a19c";
          color = "#fff";
        }

        return (
          <div
            style={{
              textAlign: "center",
              background: bgColor,
              color: color,
              paddingBottom: "10px",
              paddingTop: "10px",
              minWidth: "130px",
            }}
          >
            {o.status}
          </div>
        );
      },
    },
    // {
    //   title: "",
    //   key: "action",
    //   render(o) {
    //     const url = "/order/" + o.id;
    //     return (
    //       <span>
    //         <Link
    //           to={{
    //             pathname: url,
    //           }}
    //         >
    //           <Button type="primary" size="large">
    //             <span className="nav-text">Chi tiết</span>
    //           </Button>
    //         </Link>
    //       </span>
    //     );
    //   },
    // },
  ];

  return (
    <div>
      <div>
        <h3> {timeUpdate}</h3>
        <div style={{ padding: "10px" }}></div>

        {/* <Form onFinish={onFinishSearch} name="search-order">
          <Row style={{ padding: "10px 0" }}>
            <Col span={2} style={{ marginLeft: "10px" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Tìm kiếm
                </Button>
              </Form.Item>
            </Col>

            <Col span={4} style={{ marginLeft: "10px" }}>
              <Button
                size="middle"
                style={{
                  background: "#184d47",
                  color: "#fff",
                }}
                onClick={loadData}
              >
                Hiển thị tất cả
              </Button>
            </Col>
          </Row>
        </Form> */}

        <Row>
          <Table
            style={{
              width: "auto",
              textOverflow: "ellipsis",
              // overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            dataSource={dataSource}
            columns={columns}
            expandable={{
              expandedRowRender: (o) => (
                <div>
                  <Row style={{}}>
                    <Col span={24}>
                      <p style={{ margin: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Địa chỉ:
                        </span>{" "}
                        {o.address}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "10px" }}>
                    <Col span={10} style={{ borderRight: "4px solid" }}>
                      <p style={{ margin: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          OrderNumber:
                        </span>{" "}
                        {o.orderNumber}
                      </p>
                    </Col>
                    <Col span={12} style={{ marginLeft: "10px" }}></Col>
                  </Row>
                  <Row style={{ paddingTop: "10px" }}>
                    <Col span={10} style={{ borderRight: "4px solid" }}>
                      <p style={{ margin: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Ngày tạo:
                        </span>{" "}
                        {o.dateCreate}
                      </p>
                    </Col>
                    <Col span={12} style={{ marginLeft: "10px" }}>
                      <p style={{ margin: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Tracking Link:
                        </span>{" "}
                        <a href={o.trackingLink}>{o.trackingLink}</a>
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "10px" }}>
                    <Col span={10} style={{ borderRight: "4px solid" }}>
                      <p style={{ margin: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>Rate:</span>{" "}
                        {o.rate}
                      </p>
                    </Col>
                    <Col span={12} style={{ marginLeft: "10px" }}>
                      <p style={{ margin: 0 }}>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          Tracking TBA:
                        </span>{" "}
                        {o.trackingTBA}
                      </p>
                    </Col>
                  </Row>
                </div>
              ),
            }}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ["20", "50", "70"],
            }}
          />
        </Row>
      </div>
    </div>
  );
};

const centerStyle = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export default OrderListCustomer;
