import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Checkbox,
  notification,
  Select,
  Form,
  Input,
  InputNumber,
} from "antd";
import * as API from "../code/Api";
import TextArea from "antd/lib/input/TextArea";
import { Link } from "react-router-dom";

const { Option } = Select;

const OrderDetail = (props) => {
  const [id, setId] = useState(-1);
  const [listCus, setListCus] = useState([]);
  const [listStaff, setListStaff] = useState([]);
  const [fields, setFields] = useState([]);

  const [statusMode, setStatusMode] = useState(false);

  const [quantity, setQuantity] = useState(0);

  const [price, setPrice] = useState(0);

  const [tax, setTax] = useState(0);

  const [rate, setRate] = useState(0);

  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();

  let totalPrice = 0;
  let total = 0;

  const updateStatus = () => {
    setStatusMode(!statusMode);
  };

  useEffect(() => {
    updateInfoOrder();

  }, []);

  const updateInfoOrder = () => {
    API.getAllCustomer().then((res) => {
      setListCus(res.result);
    });

    API.getAllStaff().then((res) => {
      setListStaff(res.result);
    });

    calcPrice();
    calcTotal();

    const idGet = props.match.params.id;
    setId(idGet);
    const url = "/order/get/" + idGet;
    API.getOrder(url).then((res) => {
      const o = res.result;
      setQuantity(o.quantity);
      setPrice(o.price);
      setTax(o.tax);
      setRate(o.rate);
      setStatusMode(o.statusMode);
      setImage1(o.image1)
      setImage2(o.image2)
      setImage3(o.image3)
      setFields([
        {
          name: ["id"],
          value: o.id,
        },
        {
          name: ["customerId"],
          value: o.customerId,
        },
        {
          name: ["checker"],
          value: o.checker,
        },
        {
          name: ["address"],
          value: o.address,
        },
        {
          name: ["link"],
          value: o.link,
        },
        {
          name: ["quantity"],
          value: o.quantity,
        },
        {
          name: ["price"],
          value: o.price,
        },
        {
          name: ["totalPrice"],
          value: o.totalPrice,
        },
        {
          name: ["tax"],
          value: o.tax,
        },
        {
          name: ["orderNumber"],
          value: o.orderNumber,
        },
        {
          name: ["trackingLink"],
          value: o.trackingLink,
        },
        {
          name: ["trackingTBA"],
          value: o.trackingTBA,
        },
        {
          name: ["rate"],
          value: o.rate,
        },
        {
          name: ["totalVNA"],
          value: o.totalVNA,
        },
        {
          name: ["status"],
          value: o.status,
        },
        {
          name: ["note"],
          value: o.note,
        },
        {
          name: ["dateCreate"],
          value: o.dateCreate,
        },
        {
          name: ["dateEdit"],
          value: o.dateEdit,
        },
        {
          name: ["statusMode"],
          checked: o.statusMode,
        },
        {
          name: ["image1"],
          value: o.image1,
        },
        {
          name: ["image2"],
          value: o.image2,
        },
        {
          name: ["image3"],
          value: o.image3,
        },
      ]);
    });
  };

  const openNotification = (status, err) => {
    let msg, des;
    if (status == "success") {
      msg = "Chỉnh sửa mới thành công";
      des = "";
    } else {
      msg = "Có lỗi xảy ra";
      des = err;
    }
    const args = {
      message: msg,
      description: des,
      duration: 0,
    };
    notification.open(args);
  };

 

  function onChangeQuantiy(value) {
    setQuantity(value);
    calcPrice();
    calcTotal();
  }
  function onChangePrice(value) {
    setPrice(value);
    calcPrice();
    calcTotal();
  }

  function onChangeTax(value) {
    setTax(value);
    calcPrice();
    calcTotal();
  }

  function onChangeRate(value) {
    setRate(value);
    calcPrice();
    calcTotal();
  }

  const calcTotal = () => {
    total = (price * quantity + tax) * rate;
  };

  const calcPrice = () => {
    totalPrice = price * quantity;
  };
  calcPrice();
  calcTotal();


  const onFinish = (order) => {
    order.id = id;
    order.totalPrice = totalPrice;
    order.totalVNA = total;

    order.statusMode = statusMode;
    API.updateOrder(order)
      .then((res) => {
        if (res.result.id != null) {
          openNotification(res.message);
        } else {
          openNotification("error", res.result);
        }
        updateInfoOrder();
      })
      .catch((er) => {
        openNotification("Có lỗi xảy ra vui lòng thử lại");
      });

  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };


  return (
    <div>
      <h3>
        Chi tiết đơn hàng <span style={{ fontWeight: "bold" }}>#{id}</span>
      </h3>
      <Form
        {...layout}
        fields={fields}
        onFinish={onFinish}
        name="add-order"
        onFieldsChange={(changedFields, allFields) => {
          setImage1(allFields[14].value)
          setImage2(allFields[15].value)
          setImage3(allFields[16].value)
        }}
      >
        <Row style={{ paddingTop: "10px" }}>
          <Col span={10} style={{ borderRight: "4px solid" }}>
            <Form.Item
              name="customerId"
              label="Khách hàng"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Chọn khách hàng"
                optionFilterProp="children"
       

              >
                {listCus &&
                  listCus.map((cus) => (
                    <Option key={cus.id} value={cus.id}>
                      {cus.id} - {cus.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item
              name="address"
              label="Địa chỉ"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item
              name="link"
              label="Link sản phẩm"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item
              name="quantity"
              label="Số lượng"
              rules={[{ required: true }]}
            >
              <InputNumber onChange={onChangeQuantiy} min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item
              name="price"
              label="Giá sản phẩm"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ minWidth: "200px" }}
                size="large"
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={onChangePrice}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <h3 style={{ marginLeft: "60px" }}>
              {" "}
              Tổng tiền hàng $: {totalPrice.toLocaleString()}
            </h3>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item
              name="rate"
              label="Rate VNĐ"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ minWidth: "300px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/(,*)/g, "")}
                onChange={onChangeRate}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="tax" label="Tax " rules={[{ required: true }]}>
              <InputNumber
                style={{ minWidth: "300px" }}
                size="large"
                formatter={(value) =>
                  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={onChangeTax}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <h3 style={{ marginLeft: "20px" }}>
              {" "}
              Tổng tiền VNĐ : {total.toLocaleString()} VNĐ
            </h3>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="note" label="Note" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item name="checker" label="Nhân viên">
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Chọn nhân viên"
                optionFilterProp="children"
              >
                {listStaff &&
                  listStaff.map((staff) => (
                    <Option key={staff.id} value={staff.id}>
                      {staff.id} - {staff.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="orderNumber" label="Order Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item name="trackingLink" label="Tracking Link">
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="trackingTBA" label="Tracking TBA">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col
            span={10}
            style={{ paddingRight: "10px", borderRight: "4px solid" }}
          >
            <Form.Item name="statusMode" label="Tự động cập nhật:">
              <Checkbox
                style={{ paddingLeft: "20px" }}
                checked={statusMode}
                onChange={updateStatus}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <Form.Item name="status" label="Trạng thái">
              <Select
                disabled={statusMode}
                showSearch
                style={{ width: 200 }}
                placeholder="Trạng thái"
              >
                <Option key="WAITING">WAITING</Option>
                <Option key="IN_PROGRESS">IN_PROGRESS</Option>
                <Option key="PLACE_ORDER">PLACE_ORDER</Option>
                <Option key="SHIPPING_SOON">SHIPPING_SOON</Option>
                <Option key="IN_TRANSIT">IN_TRANSIT</Option>
                <Option key="DEVERED">DEVERED</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col span={10}>
            <Form.Item name="image1" label="Ảnh 1">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <img
              // key={image.id}
              style={{
                width: "300px",
                objectFit: "cover",
              }}
              src={image1}
              alt=""
            />
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col span={10}>
            <Form.Item name="image2" label="Ảnh 2">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <img
              // key={image.id}
              style={{
                width: "300px",
                objectFit: "cover",
              }}
              src={image2}
              alt=""
            />
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px" }}>
          <Col span={10}>
            <Form.Item name="image3" label="Ảnh 3">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginLeft: "10px" }}>
            <img
              // key={image.id}
              style={{
                width: "300px",
                objectFit: "cover",
              }}
              src={image3}
              alt=""
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "10px" }}>
          <Col span={6}>
            <Button
              style={{ width: "200px", background: "#96bb7c", color: "white" }}
              size="large"
              type="info"
              htmlType="submit"
            >
              <Link to="/order-tracking" />
              Quay lại quản lý
            </Button>
          </Col>
          <Col span={6} style={{ marginLeft: "10px" }}>
            <Form.Item style={style.col}>
              <Button
                style={{ width: "200px" }}
                size="large"
                type="primary"
                htmlType="submit"
              >
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
          <Col span={6} style={{ marginLeft: "10px" }}>
            <Form.Item style={style.col}>
              <Button
                style={{ width: "200px" }}
                size="large"
                type="danger"
                onClick={() => {
                  let result = window.confirm("bạn có muốn xoá");
                  if (result) {
                    const url = "/order/delete/" + id;
                    API.deleteOrder(url).then( res => {
          
                        props.history.push("/order-tracking",'history');
                    });
                   //
                  
                  }
                }}
              >
                Xoá
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const style = {
  col: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  inp: {
    paddingLeft: 10,
  },
};

export default OrderDetail;
