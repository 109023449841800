import React, { useState, useEffect } from "react";
import { Tabs, message } from "antd";
import UserList from "../components/UserList";
import * as API from "../code/Api";

const { TabPane } = Tabs;

const UserManagerment = () => {
  const [listUser, setListUser] = useState([]);
  const [listCus, setListCus] = useState([]);
  const [listStaff, setListStaff] = useState([]);

  useEffect(() => {
    message.warning("Đang load dữ liệu");
    API.getAllUser().then((res) => {
      setListUser(res.result);
    });
    API.getAllCustomer().then((res) => {
      setListCus(res.result);
    });
    API.getAllStaff().then((res) => {
      setListStaff(res.result);
    });
  }, []);

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Tất cả" key="all">
        <h3> Toàn bộ </h3>
        <UserList user={listUser} />
      </TabPane>
      <TabPane tab="Khách hàng" key="cus">
        <h3> Khách hàng </h3>
        <UserList user={listCus} />
      </TabPane>
      <TabPane tab="Nhân viên" key="staff">
        <h3> Nhân Viên </h3>
        <UserList user={listStaff} />
      </TabPane>
    </Tabs>
  );
};

export default UserManagerment;
