import React, { useState } from "react";
import {
  notification,
  Select,
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Tag,
  Modal,
  Table,
} from "antd";
import * as API from "../code/Api";

const { Option, OptGroup } = Select;
const { TextArea } = Input;
const RevenueList = (props) => {
  let btn = "update";
  const [displayModal, setDisplayModal] = useState(false);

  const [ModalConent, setModalConent] = useState();
  const [fields, setFields] = useState([]);

  const getRole = (role) => {
    if (role === "admin") {
      return "Quản lý";
    }

    if (role === "customer") {
      return "Khách hàng";
    }
    if (role === "staff") {
      return "Nhân viên";
    }
  };

  const dataSource = props.listRevenue.map((r) => {
    let account, note;
    if (r.note != null) {
      note = r.note;
    }

    if (r.account != null) {
      account = r.account;
    }

    return {
      key: r.revenueId,
      revenueId: r.revenueId,
      dateCreate: r.dateCreate,
      content: r.content,
      customer: account,
      note: r.note,
      price: r.price,
      category: r.category,
    };
  });

  const getCustomer = (cus) => {
    if (cus != undefined) {
      return cus.id + " - " + cus.name;
    } else {
      return "";
    }
  };

  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 12 },
  };

  const getIdCus = (cus) => {
    if (cus !== undefined) {
      return cus.id;
    }
  };

  const deleteRevenue = (revenue) => {
    let result = window.confirm("Bạn có muốn xoá");
    if (result) {
      const url = "/revenue/remove/" + revenue.revenueId;
      API.deleteRevenue(url).then((res) => {
        if (res.result != null) {
          openNotification("success", "Cập nhật thành công");
          props.callBack();
          setDisplayModal(false);
        } else {
          openNotification("err", "Bị phụ thuộc không thể xoá");
        }
      });
    }
  };

  const updateRevenue = (revenue) => {
    API.editRevenue(revenue).then((res) => {
      if (res.result != null) {
        openNotification("success", "Cập nhật thành công");
        props.callBack();
        setDisplayModal(false);
      } else {
        openNotification("err", "Có lỗi");
      }
    });
  };

  const onFinishForm = (revenue) => {
    if (btn === "delete") {
      deleteRevenue(revenue);
    } else if (btn === "update") {
      updateRevenue(revenue);
    }
  };

  const openNotification = (status, err) => {
    let msg, des;
    if (status == "success") {
      msg = "Cập nhật thành công";
      des = "";
    } else {
      msg = "Có lỗi xảy ra";
      des = err;
    }
    const args = {
      message: msg,
      description: des,
      duration: 0,
    };
    notification.open(args);
  };

  const showModal = (revenue) => {
    setDisplayModal(true);
    setModalConent(
      <Form {...layout} fields={fields} onFinish={onFinishForm}>
        <Form.Item
          initialValue={revenue.revenueId}
          name="revenueId"
          label="Id"
          rules={[{ required: true }]}
        >
          <InputNumber disabled />
        </Form.Item>
        <Form.Item
          initialValue={revenue.content}
          name="content"
          label="Nội dung"
          rules={[{ required: true }]}
        >
          <TextArea rows={2} />
        </Form.Item>

        <Form.Item
          initialValue={revenue.price}
          name="price"
          label="Số tiền"
          rules={[{ required: true }]}
        >
          {/* <InputNumber style={{ minWidth: 200 }} /> */}
          <InputNumber
            style={{ minWidth: "300px" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/(,*)/g, "")}
          />
        </Form.Item>

        <Form.Item
          name="customer"
          label="Khách hàng"
          initialValue={getIdCus(revenue.customer)}
        >
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Chọn khách hàng"
          >
            <Option key={undefined}>Không chọn</Option>
            {props.listCus &&
              props.listCus.map((cus) => (
                <Option key={cus.id} value={cus.id}>
                  {cus.id} - {cus.name} - {getRole(cus.role)}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="categoryId"
          label="Danh mục"
          rules={[{ required: true }]}
          initialValue={revenue.category.id}
        >
          <Select showSearch style={{ width: 200 }} placeholder="Chọn danh mục">
            <OptGroup label="Chi">
              {props.listCharge &&
                props.listCharge.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.id} - {c.name}
                  </Option>
                ))}
            </OptGroup>
            <OptGroup label="Thu">
              {props.listReCharge &&
                props.listReCharge.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.id} - {c.name}
                  </Option>
                ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <Form.Item initialValue={revenue.note} name="note" label="Ghi chú">
          <TextArea rows={2} />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ marginLeft: "20%" }}
            type="primary"
            htmlType="submit"
            name="update"
            onClick={() => (btn = "update")}
          >
            Cập nhật
          </Button>
          <Button
            style={{ marginLeft: "10%" }}
            htmlType="submit"
            onClick={() => (btn = "delete")}
          >
            Xoá
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const getTag = (cat) => {
    if (cat.charge) {
      return (
        <Row>
          <Col span={18}>
            {cat.id} - {cat.name}
          </Col>
          <Col>
            <Tag color="#87d068">Thu</Tag>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col span={18}>
          {cat.id} - {cat.name}
        </Col>
        <Col>
          <Tag color="#ffa36c">Chi</Tag>
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Mã </div>,
      key: "id",
      render(r) {
        return <div style={{ textAlign: "center" }}>{r.revenueId}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Ngày tạo </div>,
      key: "dateCreate",
      render(r) {
        return <div style={{ textAlign: "center" }}>{r.dateCreate}</div>;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Nội dung </div>,
      key: "content",
      render(r) {
        return (
          <div style={{ textAlign: "center", minWidth: 200, maxWidth: 275 }}>
            {r.content}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Khách hàng</div>,
      key: "content",
      render(r) {
        return (
          <div style={{ textAlign: "center" }}>{getCustomer(r.customer)}</div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Tiền</div>,
      key: "price",
      render(r) {
        return (
          <div style={{ textAlign: "center" }}>
            {r.price.toLocaleString()} VNĐ
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Thu/Chi</div>,
      key: "category",
      render(r) {
        return (
          <div style={{ textAlign: "left", minWidth: 200 }}>
            {getTag(r.category)}
          </div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Ghi chú</div>,
      key: "note",
      render(r) {
        return (
          <div style={{ textAlign: "center", maxWidth: 150 }}>{r.note}</div>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Hành động</div>,
      key: "delete",
      render(r) {
        return (
          <div>
            <Button type="primary" onClick={() => showModal(r)}>
              Chỉnh sửa
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Row>
        <Modal
          onCancel={() => setDisplayModal(false)}
          destroyOnClose={true}
          visible={displayModal}
          title="Chỉnh sửa nội dung"
          footer={null}
          width={800}
        >
          {ModalConent}
        </Modal>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: ["50", "70", "100"],
          }}
        />
      </Row>
    </div>
  );
};

export default RevenueList;
