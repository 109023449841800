import React, { useState, useEffect } from "react";
import { Form, Input, Button, Col, Row, Spin, message, Progress } from "antd";
import * as API from "../code/Api";
import ListInfo from "../components/ListInfo";

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 16,
  },
};

const GetInfo = () => {
  const [listResult, setListResult] = useState([]);
  const [spinDisplay, setSpinDisplay] = useState(false);
  const [fields, setFields] = useState();
  const [percent, setPercent] = useState(0);
  const [displayProgress, setDisplayProgress] = useState(false);
  let items = 0;

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function setProgess() {
    const time = items * 20;
    for (let i = 0; i < 100; i++) {
      await sleep(time);
      setPercent(i);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    message.warning("Đang load dữ liệu");
    setPercent(0);
    setDisplayProgress(true);
    setProgess();
    setSpinDisplay(true);
    API.getListProductUser().then((res) => {
      if (res.result != null) {
        console.log("co vao");
        items = res.result.length;
        if (items > 0) {
          let links = res.result.toString() + "";
          links = links.replace(/,/gi, "\n");
          setFields([
            {
              name: ["linkList"],
              value: links,
            },
          ]);
          API.getInfoProductUser().then((res) => {
            setListResult(res.result);
            setPercent(100);
            setDisplayProgress(false);
            setSpinDisplay(false);
          });
        }
      } else {
        setDisplayProgress(false);
        setSpinDisplay(false);
      }
    });
  };

  const getProgres = () => {
    if (displayProgress) {
      return (
        <Progress
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          percent={percent}
        />
      );
    }
  };

  const onFinish = (values) => {
    if (values.linkList != undefined) {
      let array = values.linkList.split("\n");
      items = array.length;
      setListResult([]);
      console.log(array);
      API.editListProductInfoUser(array).then((res) => {
        console.log(res);
        loadData()
      });

      // getInfoApi(array);
    }
  };

  const getInfoApi = (array) => {
    API.getInfo(array)
      .then((res) => {
        if (res.status === 200) {
          setListResult(res.result);
          setPercent(100);
          setDisplayProgress(false);
        } else {
          message.warn("Link có lỗi vui lòng nhập lại");
        }
        setSpinDisplay(false);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  return (
    <div>
      <h2>Lấy thông tin sản phẩm Amazon</h2>
      {getProgres()}

      <Spin tip="Đang load dữ liệu vui lòng chờ ..." spinning={spinDisplay}>
        <Row style={{ paddingTop: 20 }}>
          <Col span={24}>
            <Form
              {...layout}
              name="nest-messages"
              onFinish={onFinish}
              fields={fields}
            >
              <Form.Item name="linkList" label="Link tracking">
                <Input.TextArea rows={10} />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    navigator.clipboard.readText().then((text) => {
                      setFields([
                        {
                          name: ["linkList"],
                          value: text,
                        },
                      ]);
                    });
                  }}
                  style={{ margin: 20 }}
                >
                  Dán dữ liệu
                </Button>
                <Button type="primary" htmlType="submit">
                  Lấy thông tin
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row>
          <ListInfo info={listResult} />
        </Row>
      </Spin>
      <div style={{ paddingTop: 10 }}>Powered by Dương Phan</div>
    </div>
  );
};

export default GetInfo;
