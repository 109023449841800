import React, { useState, useEffect } from "react";
import * as API from "../code/Api";
import { Table, Button, Row, Col, message, Select, Form, Tabs } from "antd";
import OrderListCustomer from "../components/OrderListCustomer";
const { TabPane } = Tabs;

const HomeUser = () => {
  const [listOrder, setListOrder] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    message.warning("Đang load dữ liệu");

    API.getOrderByCus().then((res) => {
      setListOrder(res.result);
    });
  };

  return (
    <Tabs defaultActiveKey="all">
      <TabPane tab="TẤT CẢ" key="all">
        <h3> Tất cả đơn hàng</h3>
        <OrderListCustomer status="ALL" />
      </TabPane>
      <TabPane tab="WAITING" key="waiting">
        <h3> Chờ xử lý</h3>
        <OrderListCustomer status="WAITING" />
      </TabPane>
      <TabPane tab="IN_PROGRESS" key="inprogress">
        <h3> Đang xử lý</h3>
        <OrderListCustomer status="IN_PROGRESS" />
      </TabPane>
      <TabPane tab="ORDER_PLACED" key="place-order">
        <h3> Đang đặt hàng</h3>
        <OrderListCustomer status="ORDER_PLACED" />
      </TabPane>
      <TabPane tab="SHIPPING_SOON" key="shipping-soon">
        <h3> Sắp giao hàng</h3>
        <OrderListCustomer status="SHIPPING_SOON" />
      </TabPane>
      <TabPane tab="IN_TRANSIT" key="in_transit">
        <h3> Đang vận chuyển</h3>
        <OrderListCustomer status="IN_TRANSIT" />
      </TabPane>
      <TabPane tab="DELIVERED" key="delivered">
        <h3> Đã giao hàng</h3>
        <OrderListCustomer status="DELIVERED" />
      </TabPane>
    </Tabs>
  );
};

export default HomeUser;
