import React from "react";
import { Table, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";

const UserList = (props) => {
  const dataSource = props.user.map((o) => {
    let address, facebook, phone, dateCreate, dateEdit;

    if (o.address != null) {
      address = o.address;
    }

    if (o.facebook != null) {
      facebook = o.facebook;
    }

    if (o.phone != null) {
      phone = o.phone;
    }

    if (o.dateCreate != null) {
      dateCreate = o.dateCreate;
    }

    if (o.dateEdit != null) {
      dateEdit = o.dateEdit;
    }

    return {
      key: o.id,
      name: o.name,
      address: address,
      password: o.password,
      facebook: facebook,
      phone: phone,
      dateCreate: dateCreate,
      dateEdit: dateEdit,
      role: o.role,
      email: o.email,
      status: o.status,
      id: o.id
    };
  });

  const columns = [
    {
      title: "Mã Account",
      key: "id",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.id}</div>;
      },
    },
    {
      title: "Tên",
      key: "name",
      render(o) {
        return (
          <div style={{ width: "160px", textAlign: "center" }}>{o.name}</div>
        );
      },
    },
    // {
    //   title: "Địa chỉ",
    //   key: "address",
    //   render(o) {
    //     return <div style={{ textAlign: "center" }}>{o.address}</div>;
    //   },
    // },

    // {
    //   title: "Facebook",
    //   key: "facebook",
    //   render(o) {
    //     return (
    //       <div style={{ width: "50px !important", textAlign: "center" }}>
    //         $ {o.facebook}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Điện thoại",
      key: "phone",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.phone}</div>;
      },
    },
    // {
    //   title: "Ngày tạo",
    //   key: "dateCreate",
    //   render(o) {
    //     return <div>{o.dateCreate}</div>;
    //   },
    // },
    {
      title: "Quyền",
      key: "role",
      render(o) {
        return <div>{o.role}</div>;
      },
    },
    {
      title: "Email",
      key: "email",
      render(o) {
        return <div>{o.email}</div>;
      },
    },

    {
      title: "Trạng thái",
      key: "status",
      render(o) {
        return <div style={{ textAlign: "center" }}>{o.status}</div>;
      },
    },
    {
      title: "",
      key: "action",
      render(o) {
        const url = "/user/" + o.id;

        return (
          <span>
            <Link
              to={{
                pathname: url,
              }}
            >
              <Button type="primary">
                <span className="nav-text">Cập Nhật</span>
              </Button>
            </Link>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <div>
       <Row>
       <Table
          style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
          dataSource={dataSource}
          columns={columns}
          expandable={{
            expandedRowRender: (o) => (
              <div>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={10} style={{ borderRight: "4px solid" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Facebook:</span>{" "}
                      {o.facebook}
                    </p>
                  </Col>
                  <Col span={12} style={{ marginLeft: "10px" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Địa chỉ:</span>{" "}
                      {o.address}
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col span={10} style={{ borderRight: "4px solid" }}>
                    <p style={{ margin: 0 }}>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>Ngày tạo:</span>{" "}
                      {o.dateCreate}
                    </p>
                  </Col>
                  <Col span={12} style={{ marginLeft: "10px" }}>
                    <p style={{ margin: 0 }}></p>
                  </Col>
                </Row>
              </div>
            ),
          }}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "70"],
          }}
        />
       </Row>
      </div>
    </div>
  );
};

export default UserList;
