import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import auth from "../auth";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  ExpandOutlined,
  AppleOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
const { SubMenu } = Menu;

const Nav = () => {
  const handleClick = (e) => {
    if (e.key === "logout") {
      auth.logout(() => {
        auth.authenticated = false;
        window.location.href = "/";
        localStorage.clear();
      });
    }
  };

  const getHome = () => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      return (
        <Menu.Item key="home">
          <HomeOutlined />
          <span className="nav-text">Trang chủ</span>
          <Link to="/home" />
        </Menu.Item>
      );
    }
    if (role === "customer") {
      return (
        <Menu.Item key="home">
          <HomeOutlined />
          <span className="nav-text">Trang chủ</span>
          <Link to="/home-user" />
        </Menu.Item>
      );
    }
  };

  const getHistoryNav = () => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      return (
        <Menu.Item key="6">
          <HistoryOutlined />
          <span className="nav-text">Lịch sử</span>
          <Link to="/history" />
        </Menu.Item>
      );
    }
  };

  const getRevenueNav = () => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      return (
        <SubMenu
          key="sub-revenue"
          title={
            <span>
              <ExpandOutlined />
              <span>Quản lý Thu chi</span>
            </span>
          }
        >
          <Menu.Item key="manage-revenue">
            <HistoryOutlined />
            <span className="nav-text">Quản lý thu chi</span>
            <Link to="/manage-revenue" />
          </Menu.Item>

          <Menu.Item key="category-revenue">
            <HistoryOutlined />
            <span className="nav-text">Quản lý danh mục</span>
            <Link to="/category-revenue" />
          </Menu.Item>
        </SubMenu>
      );
    }
  };

  const addOrder = () => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      return (
        <SubMenu
          key="sub1"
          title={
            <span>
              <ExpandOutlined />
              <span>Quản lý đơn hàng</span>
            </span>
          }
        >
          <Menu.Item key="manage-order">
            <ShoppingCartOutlined />
            <span className="nav-text">Quản lý đơn hàng</span>
            <Link to="/order-tracking" />
          </Menu.Item>

          <Menu.Item key="3">
            <ExpandOutlined />
            <span className="nav-text">Thêm Đơn hàng</span>
            <Link to="/add-order" />
          </Menu.Item>
        </SubMenu>
      );
    }
  };

  const getUserManagement = () => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      return (
        <SubMenu
          key="sub2"
          title={
            <span>
              <ExpandOutlined />
              <span>Quản lý người dùng</span>
            </span>
          }
        >
          <Menu.Item key="4">
            <UserOutlined />
            <span className="nav-text">Danh sách người dùng</span>
            <Link to="/manager-user" />
          </Menu.Item>

          <Menu.Item key="5">
            <UserOutlined />
            <span className="nav-text">Thêm người dùng</span>
            <Link to="/add-user" />
          </Menu.Item>
        </SubMenu>
      );
    }
  };

  return (
    <div>
      <Menu
        onClick={handleClick}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["home"]}
        defaultOpenKeys={["sub1", "sub2", "sub-revenue"]}
      >
        {/* {getHome()} */}

        {/* {addOrder()} */}

        {/* chi tiêu */}
        {/* {getRevenueNav()} */}
        {/* quản lý user  */}
    {getUserManagement()}

        {/* quản lý lịch sử */}
        {/* {getHistoryNav()} */}

        <Menu.Item key="update-info">
        <AppleOutlined />
            <span className="nav-text">Lấy thông tin </span>
            <Link to="/update-info" />
        </Menu.Item>


        <Menu.Item key="logout">
          <UserOutlined />
          <span className="nav-text">Đăng xuất</span>
        </Menu.Item>

        {/* <Menu.Item key="6">
          <FormOutlined />
          <span className="nav-text">Quản lý yêu cầu</span>
        </Menu.Item> */}
      </Menu>
    </div>
  );
};

export default Nav;
